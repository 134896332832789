"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.search");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _lodash = _interopRequireDefault(require("lodash"));
var _vue2Editor = require("vue2-editor");
var _log = require("@/utils/log");
var _lifehikesInitData = require("@/lifehikes-init-data");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _index = _interopRequireDefault(require("../../../../components/EventTime/index.vue"));
var _index2 = _interopRequireDefault(require("../../../../components/SearchBox/index.vue"));
var _hike = require("@/api/hike");
var _cookie = require("@/utils/cookie");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultValue = [];
var _default = exports.default = {
  name: "LoopProgram",
  components: {
    VueEditor: _vue2Editor.VueEditor,
    EventTime: _index.default,
    SearchBox: _index2.default
  },
  props: {
    parentPrograms: {
      type: Array,
      default: defaultValue
    },
    internalTitle: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    var _ref;
    return _ref = {
      loading: false,
      firstTab: "Program1",
      programIndex: 0,
      loopPrograms: [],
      hikeloopPrograms: [],
      customToolbar: [["bold"], [{
        list: "bullet"
      }]],
      timeZoneList: _lifehikesInitData.ianaTimeZone,
      programType: _lifehikesInitData.programType,
      programIcon: _lifehikesInitData.programIcon,
      programIconColor: _lifehikesInitData.programIconColor,
      showButton: _lifehikesInitData.showButton,
      selectShowButton: [],
      dialogHikeVisible: false,
      tableLoading: false,
      hikeList: [],
      total: 0,
      hikeQuery: {
        page: 1,
        limit: 10,
        sort: '-start.utc',
        q: undefined
      }
    }, (0, _defineProperty2.default)(_ref, "programIndex", undefined), (0, _defineProperty2.default)(_ref, "optoinIdx", undefined), _ref;
  },
  watch: {
    loopPrograms: {
      handler: function handler(newVal) {
        var newLoopPrograms = newVal.concat(this.hikeloopPrograms);
        (0, _log.showDebugLog)("newLoopPrograms", newLoopPrograms);
        this.updateParentProgram(newLoopPrograms);
      },
      deep: true
    },
    selectShowButton: {
      handler: function handler(newVal) {
        (0, _log.showDebugLog)("newSelectShowButton", newVal);
        this.updateSelectShowButton(newVal);
      },
      deep: true
    }
  },
  created: function created() {
    var _this = this;
    this.loopPrograms = this.parentPrograms.filter(function (p) {
      return p.type !== "hike";
    });
    this.loopPrograms.forEach(function (e, index) {
      if (e.action.text !== "") {
        _this.selectShowButton.push("Y");
      } else {
        _this.selectShowButton.push("N");
      }
      if (e.options.length === 0) {
        console.info('第' + index + ' stage ' + e.options.length);
        e.options = [];
        _this.addOption(index);
      }
    });
    console.info("selectShowButton:" + this.selectShowButton);
    this.hikeloopPrograms = this.parentPrograms.filter(function (p) {
      return p.type === "hike";
    });
    //console.info("hikeloopPrograms:" + this.hikeloopPrograms);
  },

  methods: {
    addProgram: function addProgram() {
      var newProgram = {
        //id: "",
        type: "",
        title: "",
        shortDescription: "",
        start: {
          utc: "",
          timezone: _momentTimezone.default.tz.guess(),
          local: ""
        },
        end: {
          utc: "",
          timezone: _momentTimezone.default.tz.guess(),
          local: ""
        },
        classTime: "",
        icon: this.programIcon[0].val,
        iconColor: this.programIconColor[0].val,
        action: {
          text: "",
          url: "",
          message: ""
        },
        options: []
        // createdAt: "",
        // updatedAt: ""
      };

      this.loopPrograms.push(newProgram);
      var pLength = this.loopPrograms.length;
      this.firstTab = "Program".concat(pLength);
      this.selectShowButton.push("Y");
    },
    copyLastProgram: function copyLastProgram() {
      if (this.loopPrograms.length > 0) {
        var targetIdx = this.loopPrograms.length - 1;
        var newProgram = _lodash.default.cloneDeep(this.loopPrograms[targetIdx]);
        delete newProgram.id;
        delete newProgram.createdAt;
        delete newProgram.updatedAt;
        this.loopPrograms.push(newProgram);
        var pLength = this.loopPrograms.length;
        this.firstTab = "Program".concat(pLength);
        var temp = this.selectShowButton[targetIdx];
        this.selectShowButton.push(temp);
      } else {
        (0, _log.displayPopupMessage)("Nothing to copy", "info");
      }
    },
    removeProgram: function removeProgram(idx) {
      this.loopPrograms.splice(idx, 1);
      this.firstTab = idx === 0 ? "Program1" : "Program".concat(idx);
      console.info("selectShowButton之前：" + this.selectShowButton);
      this.selectShowButton.splice(idx, 1);
      console.info("selectShowButton之后：" + this.selectShowButton);
    },
    moveNext: function moveNext(index) {
      if (index < this.loopPrograms.length) {
        console.info("selectShowButton之前：" + this.selectShowButton);
        var element = _lodash.default.cloneDeep(this.loopPrograms[index]);
        var element2 = _lodash.default.cloneDeep(this.loopPrograms[index + 1]);
        this.$set(this.loopPrograms, index, element2);
        this.$set(this.loopPrograms, index + 1, element);
        this.firstTab = "Program".concat(index + 2);
        var temp = this.selectShowButton[index];
        var nextIndex = index + 1;
        this.selectShowButton[index] = this.selectShowButton[nextIndex];
        this.selectShowButton[nextIndex] = temp;
        this.$forceUpdate();
        console.info("selectShowButton之后：" + this.selectShowButton);
      }
    },
    movePrevious: function movePrevious(index) {
      if (index > 0) {
        console.info("selectShowButton之前：" + this.selectShowButton);
        var element = _lodash.default.cloneDeep(this.loopPrograms[index]);
        var element2 = _lodash.default.cloneDeep(this.loopPrograms[index - 1]);
        this.$set(this.loopPrograms, index - 1, element);
        this.$set(this.loopPrograms, index, element2);
        this.firstTab = "Program".concat(index);
        var temp = this.selectShowButton[index];
        var nextIndex = index - 1;
        this.selectShowButton[index] = this.selectShowButton[nextIndex];
        this.selectShowButton[nextIndex] = temp;
        this.$forceUpdate();
        console.info("selectShowButton之后：" + this.selectShowButton);
      }
    },
    updateParentProgram: function updateParentProgram(res) {
      this.$emit("updateParentProgram", res);
    },
    updateSelectShowButton: function updateSelectShowButton(res) {
      this.$emit("updateSelectShowButton", res);
    },
    setShowButtom: function setShowButtom(e, index) {
      if (e === "N") {
        this.loopPrograms[index].action.text = "";
        this.loopPrograms[index].action.url = "";
        this.loopPrograms[index].action.message = "";
        this.$forceUpdate();
      }
    },
    addOption: function addOption(programIndex) {
      var newOption = {
        objectId: '',
        objectType: 'hike',
        objectData: {
          title: ''
        }
      };
      this.loopPrograms[programIndex].options.push(newOption);
    },
    removeOption: function removeOption(programIndex, optoinIdx) {
      this.loopPrograms[programIndex].options.splice(optoinIdx, 1);
    },
    moveOptionUp: function moveOptionUp(programIndex, optoinIdx) {
      var options = this.loopPrograms[programIndex].options;
      var currentOptoin = options[optoinIdx];
      this.$set(options, optoinIdx, options[optoinIdx - 1]);
      this.$set(options, optoinIdx - 1, currentOptoin);
    },
    moveOptionDown: function moveOptionDown(programIndex, optoinIdx) {
      var options = this.loopPrograms[programIndex].options;
      var currentOptoin = options[optoinIdx];
      this.$set(options, optoinIdx, options[optoinIdx + 1]);
      this.$set(options, optoinIdx + 1, currentOptoin);
    },
    addHikeInOptions: function addHikeInOptions(id, title) {
      var option = this.loopPrograms[this.programIndex].options[this.optoinIdx];
      option.objectId = id;
      option.objectData.title = title;
      this.dialogHikeVisible = false;
      this.programIndex = undefined;
      this.optoinIdx = undefined;
    },
    clearHikeResource: function clearHikeResource(programIndex, optoinIdx) {
      var option = this.loopPrograms[programIndex].options[optoinIdx];
      option.objectId = "";
      option.objectData.title = "";
    },
    openHikeDialog: function openHikeDialog(programIndex, optoinIdx) {
      this.programIndex = programIndex;
      this.optoinIdx = optoinIdx;
      this.dialogHikeVisible = true;
      this.search(1);
    },
    search: function search(pageNo) {
      this.hikeQuery.page = pageNo;
      this.hikeQuery.q = this.hikeQuery.q === '' ? undefined : this.hikeQuery.q;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.hikeQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.hikeQuery.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.tableLoading = true;
      (0, _log.showDebugLog)('Hike query', this.hikeQuery);
      (0, _hike.getHikeList)(token, this.hikeQuery).then(function (response) {
        (0, _log.showDebugLog)('Hike-Search-response', response);
        _this2.hikeList = response.data.rows;
        _this2.total = response.data.count;
        _this2.tableLoading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('hike-Search-error', error);
        _this2.tableLoading = false;
        (0, _log.displayPopupMessage)(error, 'error');
        // this.$message({ type: 'error', showClose: true, duration: 5000, message: error.response })
      });
    },
    getHikeCategory: function getHikeCategory(val) {
      if (val === '' || typeof val === 'undefined') {
        return '';
      } else {
        var hikeCategory = _lifehikesInitData.HikeCategory.find(function (p) {
          return p.val === val;
        });
        return hikeCategory === null || hikeCategory === undefined ? "" : hikeCategory.description;
      }
    },
    selectType: function selectType(e, index) {
      if (e === "externalAction") {
        this.loopPrograms[index].options = [];
        this.addOption(index);
      } else if (e === "info") {
        this.loopPrograms[index].action.url = "";
        //clear all options
        this.loopPrograms[index].options = [];
        this.addOption(index);
      } else {
        //clear all options
        this.loopPrograms[index].options = [];
        this.loopPrograms[index].action.message = "";
        this.loopPrograms[index].action.url = "";
        this.addOption(index);
      }
    }
  }
};