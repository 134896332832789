"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.array.find");
var _log = require("@/utils/log");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _lifehikesInitData = require("@/lifehikes-init-data");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "ScheduleEventTime",
  props: {
    start: {
      type: Object
    },
    end: {
      type: Object
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    startPlaceholder: {
      type: String,
      default: "UTC Start Time"
    },
    endPlaceholder: {
      type: String,
      default: "UTC End Time"
    },
    recur: {
      type: String,
      default: ""
    }
  },
  created: function created() {
    if (this.startTime.utc !== "" || this.endTime.utc !== "") {
      if (this.startTime.utc !== "") {
        var offset = this.startTime.timezone;
        var local = this.startTime.local;
        var timeZoneObj = this.initTimeZone(offset, local);
        console.info(timeZoneObj);
        this.setSelectTimeZone(timeZoneObj.offset, timeZoneObj.gmt, 'start');
      }
      if (this.endTime.utc !== "") {
        var _offset = this.endTime.timezone;
        var _local = this.endTime.local;
        var _timeZoneObj = this.initTimeZone(_offset, _local);
        console.info(_timeZoneObj);
        this.setSelectTimeZone(_timeZoneObj.offset, _timeZoneObj.gmt, 'end');
      }
    } else {
      //Cache is enabled by default. If not, use moment.tz.guess(true)
      var _offset2 = _momentTimezone.default.tz.guess();
      var _local2 = '';
      var _timeZoneObj2 = this.initTimeZone(_offset2, _local2);
      console.info(_timeZoneObj2);
      this.setSelectTimeZone(_timeZoneObj2.offset, _timeZoneObj2.gmt, 'start');
      this.setSelectTimeZone(_timeZoneObj2.offset, _timeZoneObj2.gmt, 'end');
    }
  },
  data: function data() {
    return {
      startTime: JSON.parse(JSON.stringify(this.start)),
      endTime: JSON.parse(JSON.stringify(this.end)),
      timeZoneList: _lifehikesInitData.salesForceTimeZone,
      startTimeZome: "",
      endTimeZome: ""
    };
  },
  watch: {
    start: {
      handler: function handler(newVal) {
        (0, _log.showDebugLog)("valChange", newVal);
        var start = JSON.parse(JSON.stringify(newVal));
        this.startTime = start;
        if (newVal) {
          var offset = newVal.timezone;
          var local = newVal.local;
          var timeZoneObj = this.initTimeZone(offset, local);
          console.info(timeZoneObj);
          this.setSelectTimeZone(timeZoneObj.offset, timeZoneObj.gmt, 'start');
        }
      },
      deep: true
    },
    end: {
      handler: function handler(newVal) {
        (0, _log.showDebugLog)("valChange", newVal);
        var end = JSON.parse(JSON.stringify(newVal));
        this.endTime = end;
        if (newVal) {
          var offset = newVal.timezone;
          var local = newVal.local;
          var timeZoneObj = this.initTimeZone(offset, local);
          console.info(timeZoneObj);
          this.setSelectTimeZone(timeZoneObj.offset, timeZoneObj.gmt, 'end');
        }
      },
      deep: true
    }
  },
  methods: {
    setTimeZone: function setTimeZone(e) {
      (0, _log.showDebugLog)("select timezone value", e);
      var timeZoneList = JSON.parse(JSON.stringify(_lifehikesInitData.salesForceTimeZone));
      var currentTimeZone = timeZoneList.find(function (p) {
        return p.label === e;
      });
      this.startTimeZome = currentTimeZone.label;
      this.endTimeZome = currentTimeZone.label;
      this.startTime.timezone = currentTimeZone.offset;
      this.endTime.timezone = currentTimeZone.offset;
      console.info(this.startTimeZome);
      console.info(this.endTimeZome);
      this.setUtcTime(this.startTime.local, "start");
      this.setUtcTime(this.endTime.local, "end");
    },
    setUtcTime: function setUtcTime(e, dtype) {
      if (dtype === "start") {
        if (e) {
          var offset = this.startTime.timezone;
          var local = this.startTime.local;
          var timeZoneObj = this.initTimeZone(offset, local);
          console.info(timeZoneObj);
          //this.setSelectTimeZone(timeZoneObj.offset, timeZoneObj.gmt, 'start')
          this.startTime.utc = timeZoneObj.utcDate;
        } else {
          this.startTime.utc = "";
          this.startTime.local = "";
        }
        this.$emit('update:start', this.startTime);
        (0, _log.showDebugLog)("utc-start", this.startTime.utc);
      }
      if (dtype === "end" && e !== "" && e !== null) {
        if (e) {
          var _offset3 = this.endTime.timezone;
          var _local3 = this.endTime.local;
          var _timeZoneObj3 = this.initTimeZone(_offset3, _local3);
          console.info(_timeZoneObj3);
          //this.setSelectTimeZone(timeZoneObj.offset, timeZoneObj.gmt, 'end')
          this.endTime.utc = _timeZoneObj3.utcDate;
        } else {
          this.endTime.utc = "";
          this.endTime.local = "";
        }
        this.$emit('update:end', this.endTime);
        (0, _log.showDebugLog)("utc-end", this.endTime.utc);
      }
    },
    setSelectTimeZone: function setSelectTimeZone(offset, gmt, dtype) {
      var timeZoneList = JSON.parse(JSON.stringify(_lifehikesInitData.salesForceTimeZone));
      if (dtype === "start") {
        var currentTimeZone = timeZoneList.find(function (p) {
          return p.offset === offset && p.gmt === gmt;
        });
        this.startTimeZome = currentTimeZone.label;
        this.startTime.timezone = currentTimeZone.offset;
        console.info(currentTimeZone);
      }
      if (dtype === "end") {
        var _currentTimeZone = timeZoneList.find(function (p) {
          return p.offset === offset && p.gmt === gmt;
        });
        this.endTimeZome = _currentTimeZone.label;
        this.endTime.timezone = _currentTimeZone.offset;
        console.info(_currentTimeZone);
      }
    },
    initTimeZone: function initTimeZone(offset, local) {
      console.info("当前选中的时区：" + offset);
      console.info("当前选中时区的本地时间：" + local);
      var localDate = "";
      if (local === '' || local === undefined) {
        localDate = (0, _momentTimezone.default)().parseZone(offset).local().format("YYYY-MM-DD HH:mm:ss").toString();
        localDate = _momentTimezone.default.tz(localDate, offset);
      } else {
        localDate = (0, _momentTimezone.default)(local).format("YYYY-MM-DD HH:mm:ss").toString();
        localDate = _momentTimezone.default.tz(localDate, offset);
      }
      console.info("localDate：" + localDate.format("YYYY-MM-DD HH:mm:ss"));
      var gmt = localDate.format('Z');
      console.info("gmt：" + gmt);
      var utcDate = localDate.utc().format();
      console.info("utcDate:" + utcDate);
      return {
        offset: offset,
        gmt: gmt,
        utcDate: utcDate
      };
    }
  }
};