"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.array.includes");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.search");
var _lodash = _interopRequireDefault(require("lodash"));
var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));
var _UploadExcel = _interopRequireDefault(require("@/components/UploadExcel"));
var _log = require("@/utils/log");
var _survey = require("@/api/survey");
var _cookie = require("@/utils/cookie");
var _index = _interopRequireDefault(require("../../components/SearchBox/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'LearningPassportIndex',
  components: {
    UploadExcelComponent: _UploadExcel.default,
    SearchBox: _index.default
  },
  directives: {
    elDragDialog: _elDragDialog.default
  },
  data: function data() {
    return {
      // search
      query: {
        page: 1,
        limit: 50,
        q: undefined
      },
      // table
      tableLoading: false,
      surveyList: [],
      total: 0,
      // dialog
      dialogVisible: false,
      fullscreenloading: false,
      isShowResult: false,
      noOfSuccess: 0,
      noOfFail: 0,
      tableData: [],
      tableHeader: [],
      smallDrop: 'smallDrop'
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    sort: function sort(data) {
      var prop = data.prop,
        order = data.order;
      if (prop === 'name') {
        order === 'ascending' ? this.query.sort = '+name' : this.query.sort = '-name';
      }
      if (prop === 'email') {
        order === 'ascending' ? this.query.sort = '+email' : this.query.sort = '-email';
      }
      if (prop === 'createdAt') {
        order === 'ascending' ? this.query.sort = '+createdAt' : this.query.sort = '-createdAt';
      }
      if (prop === 'updatedAt') {
        order === 'ascending' ? this.query.sort = '+updatedAt' : this.query.sort = '-updatedAt';
      }
      this.search(1);
    },
    search: function search(pageNo) {
      this.query.page = pageNo;
      this.query.q = this.query.q === '' ? undefined : this.query.q;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.tableLoading = true;
      (0, _log.showDebugLog)('LP survey query', this.query);
      (0, _survey.getLPSurvey)(token, this.query).then(function (response) {
        (0, _log.showDebugLog)('LP survey-Search-response', response);
        _this.surveyList = response.data.rows;
        _this.total = response.data.count;
        _this.tableLoading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('LP survey-Search-error', error);
        _this.tableLoading = false;
        (0, _log.displayPopupMessage)(error, 'error');
        // this.$message({ type: 'error', showClose: true, duration: 5000, message: error.response })
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.query.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
    // dialog methods
    handleDrag: function handleDrag() {
      // this.$refs.select.blur()
    },
    openDialog: function openDialog() {
      this.tableData = [];
      this.tableHeader = [];
      this.isShowResult = false;
      this.dialogVisible = true;
    },
    beforeUpload: function beforeUpload(file) {
      // console.log('upload excel file', file)
      return true;
    },
    handleSuccess: function handleSuccess(_ref) {
      var results = _ref.results,
        header = _ref.header;
      // console.log('upload excel file-result', results)
      // console.log('upload excel file-header', results)
      this.tableData = _lodash.default.map(results, function (r) {
        var rawDataQuestions = [];
        header.forEach(function (h) {
          var include = ['#', 'Let\'s start with your name.', 'What is your LifeHikes user ID?'];
          if (/\[(.*?)\]/g.test(h) || include.includes(h)) {
            rawDataQuestions.push(h);
          }
        });
        var rawData = rawDataQuestions.map(function (h) {
          var answer = r[h];
          var match = /\[(.*?)\]/g.exec(h.trim());
          var categoryTypeTag = ['', '', ''];
          if (match !== null) {
            categoryTypeTag = match[1].split('-');
            if (categoryTypeTag.length < 3) {
              for (var i = categoryTypeTag.length, l = 3; i < l; i++) {
                categoryTypeTag.push('');
              }
            }
          }
          return {
            question: h.trim(),
            answer: answer.toString().trim(),
            category: categoryTypeTag[0].trim().toLowerCase(),
            type: categoryTypeTag[1].trim().toLowerCase(),
            tag: categoryTypeTag[2].trim().toLowerCase()
          };
        });
        return {
          hikeId: _lodash.default.trim(r['#']),
          email: _lodash.default.trim(_lodash.default.toLower(r['What is your LifeHikes user ID?'])),
          name: _lodash.default.trim(r['Let\'s start with your name.']),
          rawData: rawData,
          startDate: _lodash.default.trim(r['Start Date (UTC)']),
          submitDate: _lodash.default.trim(r['Submit Date (UTC)']),
          networkId: _lodash.default.trim(r['Network ID'])
        };
      });
      this.tableHeader = header;
    },
    EmailFormatErrorStyle: function EmailFormatErrorStyle(email) {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return !re.test(email) && email !== '' ? 'error' : '';
    },
    doUpload: function doUpload() {
      if (this.tableData.length <= 0) {
        var msg = this.$i18n.t('otrTraining.msgEmptyRecord');
        (0, _log.displayPopupMessage)(msg, 'error');
        return false;
      }
      this.fullscreenloading = true;
      this.isShowResult = false;
      this.noOfFail = 0;
      this.noOfSuccess = 0;
      this.saveLPSurvey();
    },
    saveLPSurvey: function saveLPSurvey() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.isShowResult = true;
      this.tableData.forEach(function (d, i) {
        var data = {
          typeformId: d.hikeId,
          email: d.email,
          name: d.name,
          rawData: d.rawData,
          startDate: d.startDate,
          submitDate: d.submitDate,
          networkId: d.networkId
        };
        (0, _survey.saveLPSurvey)(token, data).then(function (response) {
          (0, _log.showDebugLog)('saveLPSurvey-API-OK', response.data);
          _this2.tableData[i].result = 'Success';
          _this2.$set(_this2.tableData, i, _this2.tableData[i]);
          _this2.noOfSuccess += 1;
          (0, _log.showDebugLog)('Table content row', i);
          _this2.fullscreenloading = false;
          _this2.getList();
        }).catch(function (error) {
          // showDebugLog('saveLPSurvey-API-Fail', error.response)
          var msg = typeof error.response === 'undefined' ? error.toString() : error.response.data.message;
          _this2.tableData[i].result = 'Fail:' + msg;
          _this2.$set(_this2.tableData, i, _this2.tableData[i]);
          _this2.noOfFail += 1;
          // showDebugLog('saveLPSurvey-API-Fail', msg)
          (0, _log.showDebugLog)('Table content row', i);
          _this2.fullscreenloading = false;
        });
      });
    }
  }
};