"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _UserCard = _interopRequireDefault(require("./components/UserCard"));
var _Activity = _interopRequireDefault(require("./components/Activity"));
var _Timeline = _interopRequireDefault(require("./components/Timeline"));
var _Account = _interopRequireDefault(require("./components/Account"));
var _Address = _interopRequireDefault(require("./components/Address"));
var _Badge = _interopRequireDefault(require("./components/Badge"));
var _Education = _interopRequireDefault(require("./components/Education"));
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _log = require("@/utils/log");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Profile',
  components: {
    UserCard: _UserCard.default,
    Activity: _Activity.default,
    Timeline: _Timeline.default,
    Account: _Account.default,
    Address: _Address.default,
    Badge: _Badge.default,
    Education: _Education.default
  },
  data: function data() {
    return {
      user: {},
      activeTab: 'account',
      totalCoin: 0
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['name', 'avatar', 'roles', 'introduction', 'email'])),
  created: function created() {
    this.getUser();
  },
  methods: {
    getUser: function getUser() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _user.getInfo)(token).then(function (response) {
        _this.user = response.data;
        _this.getUserCoin(token, _this.user.uuid, false);
      }).catch(function (err) {
        (0, _log.showDebugLog)('page-profile-err', err);
      });
    }
  }
};