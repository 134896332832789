"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInitData = getInitData;
var _request = _interopRequireDefault(require("@/utils/request"));
function getInitData(lang) {
  return (0, _request.default)({
    url: '/info/app-init?lang=' + lang,
    method: 'get'
  });
}