"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImageCropper = _interopRequireDefault(require("@/components/ImageCropper"));
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AvatarUploadDemo',
  components: {
    ImageCropper: _ImageCropper.default,
    PanThumb: _PanThumb.default
  },
  data: function data() {
    return {
      imagecropperShow: false,
      imagecropperKey: 0,
      image: 'https://wpimg.wallstcn.com/577965b9-bb9e-4e02-9f0c-095b41417191'
    };
  },
  methods: {
    cropSuccess: function cropSuccess(resData) {
      this.imagecropperShow = false;
      this.imagecropperKey = this.imagecropperKey + 1;
      this.image = resData.files.avatar;
    },
    close: function close() {
      this.imagecropperShow = false;
    }
  }
};