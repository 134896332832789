"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Kanban = _interopRequireDefault(require("@/components/Kanban"));
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'DragKanbanDemo',
  components: {
    Kanban: _Kanban.default
  },
  data: function data() {
    return {
      group: 'mission',
      list1: [{
        name: 'Mission',
        id: 1
      }, {
        name: 'Mission',
        id: 2
      }, {
        name: 'Mission',
        id: 3
      }, {
        name: 'Mission',
        id: 4
      }],
      list2: [{
        name: 'Mission',
        id: 5
      }, {
        name: 'Mission',
        id: 6
      }, {
        name: 'Mission',
        id: 7
      }],
      list3: [{
        name: 'Mission',
        id: 8
      }, {
        name: 'Mission',
        id: 9
      }, {
        name: 'Mission',
        id: 10
      }]
    };
  }
};