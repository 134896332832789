"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/web.dom.iterable");
var _lifehikesInitData = require("@/lifehikes-init-data");
var _settings = require("@/settings");
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _log = require("@/utils/log");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _ga = require("@/utils/ga");
var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));
var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));
var _lodash = _interopRequireDefault(require("lodash"));
var _autoTableHeight = require("@/views/mixins/autoTableHeight");
var _index2 = _interopRequireDefault(require("../../../components/SearchBox/index.vue"));
var _role = require("@/api/role");
var _utils = require("@/utils");
var Constants = _interopRequireWildcard(require("@lifehikes/lh-js-common-lib"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserList',
  directives: {
    elDragDialog: _elDragDialog.default
  },
  components: {
    UploadExcelComponent: _index.default,
    SearchBox: _index2.default
  },
  mixins: [_autoTableHeight.autoTableHeight],
  data: function data() {
    return {
      statusList: _lifehikesInitData.userStatusList,
      userList: [],
      tableLoading: false /* use for el-table */,
      total: 0,
      query: {
        page: 1,
        limit: _settings.pageSize,
        q: undefined,
        status: undefined,
        role: undefined,
        baselineDone: undefined,
        sort: '-updatedAt',
        isLoop: undefined
      },
      // Upload Dialog
      dialogVisible: false,
      newRoleList: _lifehikesInitData.newRoleList,
      userRole: Object.assign({}),
      LifeHikesSelection: [],
      DelphiSelection: []
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    hasPermission: function hasPermission(value) {
      return (0, _permission.default)(value);
    },
    doDelete: function doDelete(uuid) {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      this.tableLoading = true;
      (0, _user.deleteUserAccount)(token, uuid).then(function (response) {
        var data = response.data;
        // console.log('page-user-delete', response)

        (0, _log.displayPopupMessage)('User has been deleted successfully', 'success');
        (0, _ga.writeGaEvent)(_this, 'User', 'Delete User', email, "User Profile: ".concat(uuid));
        _this.tableLoading = false;
        _this.doSearch(_this.query.page);
      }).catch(function (error) {
        _this.tableLoading = false;
        (0, _log.showDebugLog)('Page-user-delete-Err', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    doSearch: function doSearch(page) {
      this.query.page = page;
      this.query.q = this.query.q === '' ? undefined : this.query.q;
      this.query.status = this.query.status === '' ? undefined : this.query.status;
      this.query.baselineDone = '' ? undefined : this.query.baselineDone;
      this.query.isLoop = this.query.isLoop === '' ? undefined : this.query.isLoop;
      // this.query.role = 'otr-digital-producer,otr-coach'
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.tableLoading = true;
      (0, _user.getUserList)(token, this.query).then(function (response) {
        // create a new property newIsLoop to save checkbox value
        response.data.rows.forEach(function (row) {
          row.newIsLoop = row.isLoop;
        });

        // this.list = response.data.items
        _this2.userList = response.data.rows;
        _this2.total = response.data.count;
        _this2.tableLoading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('GetUserList-api-error', error);
        _this2.tableLoading = false;
        (0, _log.displayPopupMessage)(error, 'error');
        // this.$message({ type: 'error', showClose: true, duration: 5000, message: error.response.data.message })
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.query.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$parent.$emit('onSelectionChange', val);
    },
    sort: function sort(data) {
      var prop = data.prop,
        order = data.order;
      if (prop === 'givenName') {
        order === 'ascending' ? this.query.sort = '+givenName' : this.query.sort = '-givenName';
      }
      if (prop === 'familyName') {
        order === 'ascending' ? this.query.sort = '+familyName' : this.query.sort = '-familyName';
      }
      if (prop === 'displayName') {
        order === 'ascending' ? this.query.sort = '+displayName' : this.query.sort = '-displayName';
      }
      if (prop === 'email') {
        order === 'ascending' ? this.query.sort = '+email' : this.query.sort = '-email';
      }
      if (prop === 'orgName') {
        order === 'ascending' ? this.query.sort = '+orgName' : this.query.sort = '-orgName';
      }
      if (prop === 'status') {
        order === 'ascending' ? this.query.sort = '+status' : this.query.sort = '-status';
      }
      if (prop === 'updatedAt') {
        order === 'ascending' ? this.query.sort = '+updatedAt' : this.query.sort = '-updatedAt';
      }
      if (prop === 'createdAt') {
        order === 'ascending' ? this.query.sort = '+createdAt' : this.query.sort = '-createdAt';
      }
      if (prop === 'lastLoginDate') {
        order === 'ascending' ? this.query.sort = '+lastLoginDate' : this.query.sort = '-lastLoginDate';
      }
      if (prop === 'baselineDone') {
        order === 'ascending' ? this.query.sort = '+baselineDone' : this.query.sort = '-baselineDone';
      }
      this.doSearch(1);
    },
    confirmRole: function confirmRole(uuid, userRole) {
      var _this3 = this;
      var token = (0, _cookie.getCookie)("ACCESS_TOKEN");
      var email = (0, _cookie.getCookie)("EMAIL");
      // const roles = userRole.filter(r => r !== "root");
      var LifeHikesRoles = this.LifeHikesSelection.map(function (item) {
        return item.key;
      });
      var DelphiRolles = this.DelphiSelection.map(function (item) {
        return item.key;
      });
      var AllRolles = this.newRoleList.map(function (item) {
        return item.key;
      });
      var PreUserRoses = userRole.filter(function (item) {
        return !AllRolles.includes(item);
      });
      var roles = LifeHikesRoles.concat(DelphiRolles, PreUserRoses).filter(function (r) {
        return r !== Constants.USER_ROLE_ROOT && r !== Constants.USER_ROLE_DEVELOPER;
      } //LHVO-4726 - Add developer role
      );

      (0, _role.updateUserRole)(token, uuid, roles).then(function (response) {
        var data = response.data;
        // console.log('page-permission-confirmrole', response)

        (0, _log.displayPopupMessage)("User's role has been updated successfully", "success");
        (0, _ga.writeGaEvent)(_this3, "User", "Update User Role", email, "User Profile: ".concat(uuid));
        _this3.dialogVisible = false;
        _this3.doSearch(_this3.query.page);
      }).catch(function (error) {
        (0, _log.showDebugLog)("Page-permission-confirmrole-Err", error);
        (0, _log.displayPopupMessage)(error, "error");
      });
    },
    handleEdit: function handleEdit(scope) {
      this.dialogVisible = true;
      this.userRole = (0, _utils.deepClone)(scope.row);
      this.checkData();
    },
    checkData: function checkData() {
      this.$nextTick(function () {
        var _this4 = this;
        this.newRoleList.filter(function (p) {
          return p.type === "LifeHikes";
        }).forEach(function (row) {
          if (_this4.userRole.role.includes(row.key)) {
            _this4.$refs.LifeHikesRolles.toggleRowSelection(row, true);
          }
        });
        this.newRoleList.filter(function (p) {
          return p.type === "Delphi";
        }).forEach(function (row) {
          if (_this4.userRole.role.includes(row.key)) {
            _this4.$refs.DelphiRolles.toggleRowSelection(row, true);
          }
        });
      });
    },
    LifeHikesSelectionChange: function LifeHikesSelectionChange(val) {
      this.LifeHikesSelection = val;
    },
    DelphiSelectionChange: function DelphiSelectionChange(val) {
      this.DelphiSelection = val;
    },
    disableVisble: function disableVisble(id) {
      //console.info(this.$refs[id]);
      this.$refs[id].visible = false;
    }
  }
};