"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.mockXHR = mockXHR;
require("core-js/modules/es6.regexp.constructor");
var _toConsumableArray2 = _interopRequireDefault(require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _mockjs = _interopRequireDefault(require("mockjs"));
var _utils = require("../src/utils");
var _user = _interopRequireDefault(require("./user"));
var _role = _interopRequireDefault(require("./role"));
var _article = _interopRequireDefault(require("./article"));
var _remoteSearch = _interopRequireDefault(require("./remote-search"));
var _loop = _interopRequireDefault(require("./loop"));
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var mocks = [].concat((0, _toConsumableArray2.default)(_user.default), (0, _toConsumableArray2.default)(_role.default), (0, _toConsumableArray2.default)(_article.default), (0, _toConsumableArray2.default)(_remoteSearch.default));

// for front mock
// please use it cautiously, it will redefine XMLHttpRequest,
// which will cause many of your third-party libraries to be invalidated(like progress event).
function mockXHR() {
  // mock patch
  // https://github.com/nuysoft/Mock/issues/300
  _mockjs.default.XHR.prototype.proxy_send = _mockjs.default.XHR.prototype.send;
  _mockjs.default.XHR.prototype.send = function () {
    if (this.custom.xhr) {
      this.custom.xhr.withCredentials = this.withCredentials || false;
      if (this.responseType) {
        this.custom.xhr.responseType = this.responseType;
      }
    }
    this.proxy_send.apply(this, arguments);
  };
  function XHR2ExpressReqWrap(respond) {
    return function (options) {
      var result = null;
      if (respond instanceof Function) {
        var body = options.body,
          type = options.type,
          url = options.url;
        alert(JSON.stringify(options));
        // https://expressjs.com/en/4x/api.html#req
        result = respond({
          method: type,
          body: JSON.parse(body),
          query: (0, _utils.param2Obj)(url)
        });
      } else {
        result = respond;
      }
      return _mockjs.default.mock(result);
    };
  }
  var _iterator = _createForOfIteratorHelper(mocks),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var i = _step.value;
      _mockjs.default.mock(new RegExp(i.url), i.type || 'get', XHR2ExpressReqWrap(i.response));
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

// for mock server
var responseFake = function responseFake(url, type, respond) {
  return {
    url: new RegExp("".concat(process.env.VUE_APP_BASE_API).concat(url)),
    type: type || 'get',
    response: function response(req, res) {
      console.log('request invoke:' + req.path);
      res.json(_mockjs.default.mock(respond instanceof Function ? respond(req, res) : respond));
    }
  };
};
var _default = exports.default = mocks.map(function (route) {
  return responseFake(route.url, route.type, route.response);
});