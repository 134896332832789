var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "4px",
          },
        },
        [_vm._v(" " + _vm._s(_vm.internalTitle))]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { type: "primary" },
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.sendEnrollmentToPOC()
            },
          },
        },
        [_vm._v("Send enrollment form to Client POC")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "activity-kanban" }, [
        _c(
          "div",
          { staticClass: "board-column" },
          [
            _c(
              "div",
              { staticClass: "board-column-header library" },
              [
                _vm._v("Staff Library\n        "),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      content: "OTR coach and digital producer.",
                      title: "Tip",
                      placement: "right",
                      width: "400",
                      trigger: "hover",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "otr-guide",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "question-circle-regular" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "board-column-search search" },
              [
                _c("el-input", {
                  attrs: { placeholder: "Keyword Search", size: "mini" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getStaffList($event)
                    },
                  },
                  model: {
                    value: _vm.keywordSearch,
                    callback: function ($$v) {
                      _vm.keywordSearch = $$v
                    },
                    expression: "keywordSearch",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "board-column-content",
                attrs: {
                  list: _vm.staffList,
                  options: {
                    group: { name: "staff", pull: "clone", put: false },
                  },
                  clone: _vm.cloneLibrary,
                },
                on: { change: _vm.updateLibrary },
              },
              _vm._l(_vm.staffList, function (element, idx) {
                return _c("div", { key: idx, staticClass: "board-item" }, [
                  _c("div", { staticClass: "item" }, [
                    _vm._v("ID: " + _vm._s(element.user)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _vm._v("Name: " + _vm._s(element.name)),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "board-column" },
          [
            _c("div", { staticClass: "board-column-header t1" }, [
              _vm._v("CSM"),
            ]),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "board-column-content",
                attrs: {
                  id: "manager",
                  list: _vm.mangerList,
                  options: { group: "staff" },
                },
                on: { change: _vm.updateManagerList },
              },
              _vm._l(_vm.mangerList, function (element, idx) {
                return _c("div", { key: idx, staticClass: "board-item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item blue",
                      staticStyle: { float: "right" },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-close",
                        on: {
                          click: function ($event) {
                            return _vm.removeManager(idx, _vm.mangerList)
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item blue" }, [
                    _vm._v("ID: " + _vm._s(element.user)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item blue" }, [
                    _vm._v("Name: " + _vm._s(element.name)),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "board-column" },
          [
            _c("div", { staticClass: "board-column-header t1" }, [
              _vm._v("Producer"),
            ]),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "board-column-content",
                attrs: {
                  id: "contact",
                  list: _vm.contactList,
                  options: { group: "staff" },
                },
                on: { change: _vm.updateContactList },
              },
              _vm._l(_vm.contactList, function (element, idx) {
                return _c("div", { key: idx, staticClass: "board-item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item blue",
                      staticStyle: { float: "right" },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-close",
                        on: {
                          click: function ($event) {
                            return _vm.removeContact(idx, _vm.contactList)
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item blue" }, [
                    _vm._v("ID: " + _vm._s(element.user)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item blue" }, [
                    _vm._v("Name: " + _vm._s(element.name)),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "board-column" },
          [
            _c("div", { staticClass: "board-column-header t1" }, [
              _vm._v("Client POC"),
            ]),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "board-column-content",
                attrs: {
                  id: "contact",
                  list: _vm.clientPocList,
                  options: { group: "staff" },
                },
                on: { change: _vm.updateClientPocList },
              },
              _vm._l(_vm.clientPocList, function (element, idx) {
                return _c(
                  "div",
                  { key: idx, staticClass: "board-item" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item blue",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-close",
                          on: {
                            click: function ($event) {
                              return _vm.removeClientPoc(idx, _vm.clientPocList)
                            },
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item blue" }, [
                      _vm._v("ID: " + _vm._s(element.user)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item blue" }, [
                      _vm._v("Name: " + _vm._s(element.name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item blue" }, [
                      _vm._v("Enrollment Quota:"),
                    ]),
                    _vm._v(" "),
                    _c("el-input-number", {
                      attrs: { min: 0 },
                      model: {
                        value: element.enrollmentQuota,
                        callback: function ($$v) {
                          _vm.$set(element, "enrollmentQuota", $$v)
                        },
                        expression: "element.enrollmentQuota",
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }