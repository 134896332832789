"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _rater = require("@/api/rater");
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
var _moment = _interopRequireDefault(require("moment"));
var _lodash = _interopRequireDefault(require("lodash"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'job',
  data: function data() {
    return {
      start: (0, _moment.default)().subtract(1, 'months'),
      // Hike Start Date
      end: (0, _moment.default)(),
      loading: false,
      query: {
        page: 1,
        limit: 100
      },
      jobs: [],
      jobList: [],
      raterList: []
    };
  },
  computed: {
    startDate: {
      get: function get() {
        var start = (0, _moment.default)().isoWeekYear(this.yearIso).isoWeeks(this.weekOfYear).startOf('isoWeek');
        return start.format("yyyy-MM-DD");
      }
    },
    endDate: {
      get: function get() {
        var end = (0, _moment.default)().isoWeekYear(this.yearIso).isoWeeks(this.weekOfYear).endOf('isoWeek');
        return end.format("yyyy-MM-DD");
      }
    }
  },
  created: function created() {
    this.getRaterList();
  },
  methods: {
    search: function search() {
      this.getJobList();
    },
    getJobList: function getJobList() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var start = (0, _moment.default)(this.start).format('YYYY-MM-DD');
      var end = (0, _moment.default)(this.end).format('YYYY-MM-DD');
      console.log(start);
      this.loading = true;
      (0, _rater.getJobs)(token, start, end).then(function (response) {
        console.log(response.data.tasks);
        _this.loading = false;
        _this.jobs = response.data.tasks;
        _this.jobList = _this.jobs.slice(0, _this.query.limit);
      }).catch(function (error) {
        _this.loading = false;
        (0, _log.showDebugLog)('GetJobs-api-error', error);
      });
    },
    getRaterList: function getRaterList() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var query = {
        page: 1,
        limit: 200,
        role: 'otr-rater',
        sort: 'givenName'
      };
      (0, _user.getUserList)(token, query).then(function (response) {
        // this.list = response.data.items
        _this2.raterList = response.data.rows.map(function (s) {
          return {
            uuid: s.uuid,
            name: "".concat(s.givenName, " ").concat(s.familyName)
          };
        });
      }).catch(function (error) {
        (0, _log.showDebugLog)('GetUserList-api-error', error);
      });
    },
    getUserName: function getUserName(uuid) {
      var staff = this.raterList ? this.raterList.find(function (u) {
        return u.uuid === uuid;
      }) : null;
      return staff ? staff.name : '-';
    },
    getPickupStatus: function getPickupStatus(noOfRater, assignedRater) {},
    handleSizeChange: function handleSizeChange(val) {
      this.query.limit = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.query.page = val;
      var end = val * this.query.limit;
      var start = val * this.query.limit - this.query.limit;
      this.jobList = this.jobs.slice(start, end);
    }
  }
};