"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.array.find-index");
var _slicedToArray2 = _interopRequireDefault(require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
var _hike = require("@/api/hike");
var _log = require("@/utils/log");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _lifehikesInitData = require("@/lifehikes-init-data");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SendSurveyNotification',
  data: function data() {
    return {
      hikeId: this.$route.params.id,
      internalTitle: '',
      loading: false,
      participantList: [],
      benchmarkBossSurveyId: '',
      benchmarkPeerSurveyId: '',
      benchmarkDirectReportSurveyId: '',
      summitBossSurveyId: '',
      summitPeerSurveyId: '',
      summitDirectReportSurveyId: ''
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.loading = true;

      // 1. getSurveyTeamEmails
      (0, _hike.getSurveyTeamEmails)(token, {
        hikeId: this.hikeId
      }).then(function (response) {
        (0, _log.showDebugLog)('Hike-getSurveyTeamEmails-response', response.data);
        var rows = response.data.rows;
        if (rows.length) {
          _this.benchmarkBossSurveyId = rows[0].surveys.benchmarkBossSurveyId;
          _this.benchmarkPeerSurveyId = rows[0].surveys.benchmarkPeerSurveyId;
          _this.benchmarkDirectReportSurveyId = rows[0].surveys.benchmarkDirectReportSurveyId;
          _this.summitBossSurveyId = rows[0].surveys.summitBossSurveyId;
          _this.summitPeerSurveyId = rows[0].surveys.summitPeerSurveyId;
          _this.summitDirectReportSurveyId = rows[0].surveys.summitDirectReportSurveyId;

          // 2. getEmailLog
          Promise.all([(0, _hike.getEmailLog)(token, {
            hikeId: _this.hikeId,
            surveyId: _this.benchmarkBossSurveyId
          }), (0, _hike.getEmailLog)(token, {
            hikeId: _this.hikeId,
            surveyId: _this.benchmarkPeerSurveyId
          }), (0, _hike.getEmailLog)(token, {
            hikeId: _this.hikeId,
            surveyId: _this.benchmarkDirectReportSurveyId
          }), (0, _hike.getEmailLog)(token, {
            hikeId: _this.hikeId,
            surveyId: _this.summitBossSurveyId
          }), (0, _hike.getEmailLog)(token, {
            hikeId: _this.hikeId,
            surveyId: _this.summitPeerSurveyId
          }), (0, _hike.getEmailLog)(token, {
            hikeId: _this.hikeId,
            surveyId: _this.summitDirectReportSurveyId
          })]).then(function (_ref) {
            var _ref2 = (0, _slicedToArray2.default)(_ref, 6),
              benchmarkBossResponse = _ref2[0],
              benchmarkPeerResponse = _ref2[1],
              benchmarkDirectReportResponse = _ref2[2],
              bossResponse = _ref2[3],
              peerResponse = _ref2[4],
              directReportResponse = _ref2[5];
            _this.loading = false;
            (0, _log.showDebugLog)('Hike-getEmailLog-response', benchmarkBossResponse.data);
            (0, _log.showDebugLog)('Hike-getEmailLog-response', benchmarkPeerResponse.data);
            (0, _log.showDebugLog)('Hike-getEmailLog-response', benchmarkDirectReportResponse.data);
            (0, _log.showDebugLog)('Hike-getEmailLog-response', bossResponse.data);
            (0, _log.showDebugLog)('Hike-getEmailLog-response', peerResponse.data);
            (0, _log.showDebugLog)('Hike-getEmailLog-response', directReportResponse.data);
            var benchmarkBossEmailLog = benchmarkBossResponse.data.rows;
            var benchmarkPeerEmailLog = benchmarkPeerResponse.data.rows;
            var benchmarkDirectReportEmailLog = benchmarkDirectReportResponse.data.rows;
            var submitBossEmailLog = bossResponse.data.rows;
            var summitPeerEmailLog = peerResponse.data.rows;
            var summitDirectReportEmailLog = directReportResponse.data.rows;

            // Convert to appropriate tabular data
            var participantList = [];
            rows.forEach(function (row) {
              row.bossEmails.forEach(function (email) {
                var newRow = JSON.parse(JSON.stringify(row));
                newRow.emailType = 'boss';
                newRow.email = email;
                newRow.isSendBenchmark = benchmarkBossEmailLog.findIndex(function (log) {
                  return log.participantId === row.participantId && log.toStr === email;
                }) > -1 ? 'Y' : 'N';
                newRow.isSendSummit = submitBossEmailLog.findIndex(function (log) {
                  return log.participantId === row.participantId && log.toStr === email;
                }) > -1 ? 'Y' : 'N';
                participantList.push(newRow);
              });
              row.peerEmails.forEach(function (email) {
                var newRow = JSON.parse(JSON.stringify(row));
                newRow.emailType = 'peer';
                newRow.email = email;
                newRow.isSendBenchmark = benchmarkPeerEmailLog.findIndex(function (log) {
                  return log.participantId === row.participantId && log.toStr === email;
                }) > -1 ? 'Y' : 'N';
                newRow.isSendSummit = summitPeerEmailLog.findIndex(function (log) {
                  return log.participantId === row.participantId && log.toStr === email;
                }) > -1 ? 'Y' : 'N';
                participantList.push(newRow);
              });
              row.dmEmails.forEach(function (email) {
                var newRow = JSON.parse(JSON.stringify(row));
                newRow.emailType = 'dm';
                newRow.email = email;
                newRow.isSendBenchmark = benchmarkDirectReportEmailLog.findIndex(function (log) {
                  return log.participantId === row.participantId && log.toStr === email;
                }) > -1 ? 'Y' : 'N';
                newRow.isSendSummit = summitDirectReportEmailLog.findIndex(function (log) {
                  return log.participantId === row.participantId && log.toStr === email;
                }) > -1 ? 'Y' : 'N';
                participantList.push(newRow);
              });
            });
            _this.participantList = participantList;
          }).catch(function (error) {
            (0, _log.showDebugLog)('Hike-getEmailLog-error', error);
            _this.loading = false;
            (0, _log.displayPopupMessage)(error, 'error');
          });
        }
      }).catch(function (error) {
        (0, _log.showDebugLog)('Hike-getSurveyTeamEmails-error', error);
        _this.loading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    sort_change: function sort_change(column) {
      var newItems = this.participantList.sort(this.sortFun(column.prop));
      if (column.order === "descending") {
        newItems = newItems.reverse();
      }
      this.participantList = newItems;
      //console.info("sort a:"+JSON.stringify(this.participantList));
    },
    sortFun: function sortFun(attr) {
      return function (item1, item2) {
        item1 = item1[attr].toLowerCase();
        item2 = item2[attr].toLowerCase();
        if (item1 < item2) {
          return -1;
        } else if (item1 > item2) {
          return 1;
        } else {
          return 0;
        }
      };
    },
    send: function send(row, surveyType) {
      var _this2 = this;
      this.loading = true;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _hike.sendMail)(token, {
        surveyId: this.getSurveyId(surveyType, row.emailType),
        uuid: row.participantId,
        email: row.email,
        name: row.participantGivenName + ' ' + row.participantFamilyName,
        hikeId: this.hikeId
      }).then(function (response) {
        (0, _log.showDebugLog)('Hike-sendEmail-response', response.data);

        //const msg = `Survey notification has been sent.`
        if (response.data.message === 'Fail') {
          var msg = response.data.reason;
          (0, _log.displayPopupMessage)(msg, 'error');
        } else {
          var _msg = response.data.reason;
          (0, _log.displayPopupMessage)(_msg, 'success');
        }
        _this2.getData();
      }).catch(function (error) {
        (0, _log.showDebugLog)('Hike-sendEmail-error', error);
        _this2.loading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    batchSend: function batchSend(surveyType) {
      var _this3 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      if (this.$refs.participantList.selection.length < 1) {
        (0, _log.displayPopupMessage)('Please select emails.', 'warning');
        return;
      }
      this.loading = true;
      var sendEmailPromises = this.$refs.participantList.selection.map(function (row) {
        return (0, _hike.sendMail)(token, {
          surveyId: _this3.getSurveyId(surveyType, row.emailType),
          uuid: row.participantId,
          email: row.email,
          name: row.participantGivenName + ' ' + row.participantFamilyName,
          hikeId: _this3.hikeId
        });
      });
      Promise.all(sendEmailPromises).then(function (responses) {
        (0, _log.showDebugLog)('Hike-sendEmail-response', responses);
        var msg = "Survey notifications have been sent.";
        (0, _log.displayPopupMessage)(msg, 'success');
        _this3.getData();
      }).catch(function (error) {
        (0, _log.showDebugLog)('Hike-sendEmail-error', error);
        _this3.loading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    getSurveyId: function getSurveyId(surveyType, emailType) {
      if (emailType === 'boss') {
        return this[surveyType + 'BossSurveyId'];
      } else if (emailType === 'peer') {
        return this[surveyType + 'PeerSurveyId'];
      } else if (emailType === 'dm') {
        return this[surveyType + 'DirectReportSurveyId'];
      }
    }
  }
};