"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createResource = createResource;
exports.deleteResourceById = deleteResourceById;
exports.fetchList = fetchList;
exports.getResourceById = getResourceById;
exports.getResourceByTraining = getResourceByTraining;
exports.syncResource = syncResource;
exports.updateResourceById = updateResourceById;
exports.uploadToS3 = uploadToS3;
var _request = _interopRequireDefault(require("@/utils/request"));
// import { fileURLToPath } from 'url'

function fetchList(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/resources',
    method: 'get',
    params: query,
    headers: headers
  });
}
function createResource(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/resources',
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateResourceById(token, id, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/resources/' + id,
    method: 'patch',
    headers: headers,
    data: data
  });
}
function uploadToS3(file, url) {
  var headers = {
    'Content-Type': ' '
  };
  return (0, _request.default)({
    url: url,
    method: 'put',
    headers: headers,
    data: file,
    timeout: 60000
  });
}
function syncResource(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/resources/' + id + '/sync',
    method: 'post',
    headers: headers
  });
}
function deleteResourceById(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/resources/' + id,
    method: 'delete',
    headers: headers
  });
}
function getResourceById(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/resources/' + id,
    method: 'get',
    headers: headers
  });
}
function getResourceByTraining(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/trainings/' + id + '/resources',
    method: 'get',
    headers: headers
  });
}