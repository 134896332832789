"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRule = createRule;
exports.generateIndividualReport = generateIndividualReport;
exports.generateSummaryReport = generateSummaryReport;
exports.generateTASCreditReport = generateTASCreditReport;
exports.getJobs = getJobs;
exports.getRuleList = getRuleList;
exports.releaseJob = releaseJob;
exports.updateRule = updateRule;
var _request = _interopRequireDefault(require("@/utils/request"));
function getRuleList(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/rule',
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'get',
    headers: headers,
    params: query
  });
}
function updateRule(token, obj) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/rule/' + obj.id,
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'put',
    headers: headers,
    data: obj
  });
}
function createRule(token, obj) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/rule',
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'post',
    headers: headers,
    data: obj
  });
}
function generateIndividualReport(token, year, week, raterId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/rater-assignment/individual-jobs-report/".concat(raterId, "/year/").concat(year, "/week/").concat(week),
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'get',
    headers: headers
  });
}
function generateSummaryReport(token, year, week) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/rater-assignment/jobs-summary-report/year/".concat(year, "/week/").concat(week),
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'get',
    headers: headers
  });
}
function generateTASCreditReport(token, obj) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/rater-assignment/tas-credit-report",
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'post',
    headers: headers,
    data: obj
  });
}
function getJobs(token, startFrom, startTo) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/rater-assignment/job/all?startFrom=".concat(startFrom, "&startTo=").concat(startTo),
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'get',
    headers: headers
  });
}
function releaseJob(token, raterId, jobId, userHikeId, stageId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/rater-assignment/job-release/raterId/".concat(raterId, "/jobId/").concat(jobId, "/userHikeId/").concat(userHikeId, "/stageId/").concat(stageId),
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'put',
    headers: headers
  });
}