"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userContent = userContent;
var _request = _interopRequireDefault(require("@/utils/request"));
function userContent(token, uuid) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var res = (0, _request.default)({
    url: "contents?userUuid=".concat(uuid, "&sort=createdAt"),
    method: 'get',
    headers: headers
  });
  return res;
}