"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _sortablejs = _interopRequireDefault(require("sortablejs"));
//
//
//
//
//
//
var _default = exports.default = {
  name: 'DragSelect',
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    selectVal: {
      get: function get() {
        return (0, _toConsumableArray2.default)(this.value);
      },
      set: function set(val) {
        this.$emit('input', (0, _toConsumableArray2.default)(val));
      }
    }
  },
  mounted: function mounted() {
    this.setSort();
  },
  methods: {
    setSort: function setSort() {
      var _this = this;
      var el = this.$refs.dragSelect.$el.querySelectorAll('.el-select__tags > span')[0];
      this.sortable = _sortablejs.default.create(el, {
        ghostClass: 'sortable-ghost',
        // Class name for the drop placeholder,
        setData: function setData(dataTransfer) {
          dataTransfer.setData('Text', '');
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
        },

        onEnd: function onEnd(evt) {
          var targetRow = _this.value.splice(evt.oldIndex, 1)[0];
          _this.value.splice(evt.newIndex, 0, targetRow);
        }
      });
    }
  }
};