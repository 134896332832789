"use strict";

var _typeof = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
var _cookie = require("@/utils/cookie");
var _log = require("@/utils/log");
var _hike = require("@/api/hike");
var _survey = require("@/api/survey");
var _filters = require("@/filters");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ResponseStatusReport',
  data: function data() {
    return {
      tableLoading: false,
      reportList: []
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var id = typeof this.$route.params.id === 'undefined' ? '' : this.$route.params.id;
      this.tableLoading = true;
      (0, _hike.getParticipant)(token, id).then(function (response) {
        _this.tableLoading = false;
        var participants = response.data.rows;
        var feedbackPromises = participants.map(function (participant) {
          return (0, _survey.getRespondentFeedback)(token, {
            hikeId: id,
            userId: participant.uuid
          });
        });
        _this.tableLoading = true;
        Promise.all(feedbackPromises).then(function (responses) {
          (0, _log.showDebugLog)('MGH-RespondentFeedback-response', responses);
          _this.tableLoading = false;
          _this.reportList = responses.map(function (r) {
            var data = r.data;
            data.email = participants.find(function (participant) {
              return participant.uuid === data.uuid;
            }).email;
            return data;
          });
        }).catch(function (error) {
          (0, _log.showDebugLog)('MGH-RespondentFeedback-error', error);
          _this.tableLoading = false;
          (0, _log.displayPopupMessage)(error, 'error');
        });
      }).catch(function (error) {
        _this.tableLoading = false;
        (0, _log.showDebugLog)('Hike-getParticipant-API-error', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    handleDownload: function handleDownload() {
      var _this2 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var header = ['UUID', 'Participant Name', 'Participant Email', 'Is Completed', 'Completed Datetime (UTC)', 'Type', 'Email (Boss/Peer/Direct Report)', 'Is Completed (Boss/Peer/Direct Report)', 'Completed Datetime (UTC) (Boss/Peer/Direct Report)'];
        var benchmarkData = _this2.buildExcelData('benchmark');
        var summitData = _this2.buildExcelData('summit');
        excel.export_json_to_excel_with_multiple_sheets({
          sheets: [{
            name: 'Benchmark',
            header: header,
            data: benchmarkData
          }, {
            name: 'Summit',
            header: header,
            data: summitData
          }],
          filename: 'Response Status Report'
        });
        _this2.downloadLoading = false;
      });
    },
    buildExcelData: function buildExcelData(surveyType) {
      var data = [];
      this.reportList.forEach(function (participant, index) {
        var types = [{
          k: 'boss',
          v: 'Boss'
        }, {
          k: 'peer',
          v: 'Peer'
        }, {
          k: 'directReport',
          v: 'Direct Report'
        }];
        types.forEach(function (type) {
          var typeData = participant[surveyType + 'Survey'][type.k];
          if (typeData.length > 0) {
            typeData.forEach(function (typeDataItem) {
              data.push([participant.uuid, participant.name, participant.email, participant[surveyType + 'SurveyDone'] === 'Y' ? 'Yes' : 'No', (0, _filters.dateFormat)(participant[surveyType + 'SurveyCompletedTime'], 'YYYY-MM-DD HH:mm:ss'), type.v, typeDataItem.email, typeDataItem.isCompleted === 'Y' ? 'Yes' : 'No', (0, _filters.dateFormat)(typeDataItem.completedTime, 'YYYY-MM-DD HH:mm:ss')]);
            });
          } else {
            data.push([participant.uuid, participant.name, participant.email, participant[surveyType + 'SurveyDone'] === 'Y' ? 'Yes' : 'No', (0, _filters.dateFormat)(participant[surveyType + 'SurveyCompletedTime'], 'YYYY-MM-DD HH:mm:ss'), type.v, 'No Data', 'No Data', 'No Data']);
          }
        });
      });
      return data;
    }
  }
};