"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lifehikesInitData = require("@/lifehikes-init-data");
var _settings = require("@/settings");
var _cookie = require("@/utils/cookie");
var _question = require("@/api/question");
var _log = require("@/utils/log");
var _ga = require("@/utils/ga");
var _permission = _interopRequireDefault(require("@/utils/permission"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'RaterAssignmentIndex',
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {
    hasPermission: function hasPermission(value) {
      return (0, _permission.default)(value);
    }
  }
};