"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// base on element-ui
var _default = exports.default = {
  name: 'DragDialogDemo',
  directives: {
    elDragDialog: _elDragDialog.default
  },
  data: function data() {
    return {
      dialogTableVisible: false,
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }],
      value: '',
      gridData: [{
        date: '2016-05-02',
        name: 'John Smith',
        address: 'No.1518,  Jinshajiang Road, Putuo District'
      }, {
        date: '2016-05-04',
        name: 'John Smith',
        address: 'No.1518,  Jinshajiang Road, Putuo District'
      }, {
        date: '2016-05-01',
        name: 'John Smith',
        address: 'No.1518,  Jinshajiang Road, Putuo District'
      }, {
        date: '2016-05-03',
        name: 'John Smith',
        address: 'No.1518,  Jinshajiang Road, Putuo District'
      }]
    };
  },
  methods: {
    // v-el-drag-dialog onDrag callback function
    handleDrag: function handleDrag() {
      this.$refs.select.blur();
    }
  }
};