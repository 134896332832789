"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _UserCard = _interopRequireDefault(require("./components/UserCard"));
var _GeneralInfo = _interopRequireDefault(require("./components/GeneralInfo"));
var _Address = _interopRequireDefault(require("./components/Address"));
var _Education = _interopRequireDefault(require("./components/Education"));
var _Coin = _interopRequireDefault(require("./components/Coin"));
var _Badge = _interopRequireDefault(require("./components/Badge"));
var _SkillSet = _interopRequireDefault(require("./components/SkillSet"));
var _user = require("@/api/user");
var _coin = require("@/api/coin");
var _cookie = require("@/utils/cookie");
var _log = require("@/utils/log");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _lifehikesInitData = require("@/lifehikes-init-data");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
// 权限判断指令
var _default = exports.default = {
  name: "UserEdit",
  directives: {
    permission: _index.default
  },
  components: {
    UserCard: _UserCard.default,
    GeneralInfo: _GeneralInfo.default,
    Address: _Address.default,
    Education: _Education.default,
    Coin: _Coin.default,
    Badge: _Badge.default,
    SkillSet: _SkillSet.default
  },
  data: function data() {
    return {
      loading: false,
      user: {},
      coin: {},
      activeTab: "general"
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(["name", "avatar", "roles", "introduction", "email"])),
  created: function created() {
    var token = (0, _cookie.getCookie)("ACCESS_TOKEN");
    var uuid = typeof this.$route.params.id === "undefined" ? this.user.uuid : this.$route.params.id;

    // console.log('page-useredit-uuid', this.$route.params.id)
    this.getUser(token, uuid);
    if ((0, _permission.default)(["root", "otr-digital-producer"])) {
      this.getUserCoin(token, uuid, false);
    }
  },
  methods: {
    checkPermission: _permission.default,
    getUser: function getUser(token, uuid) {
      var _this = this;
      this.loading = true;
      (0, _user.getUser)(token, uuid).then(function (response) {
        _this.loading = false;
        // console.log('page-useredit-response', response.data)
        _this.user = response.data;
        var userRole = _this.user.role;
        var newUserRoses = _lifehikesInitData.newRoleList.filter(function (p) {
          return userRole.includes(p.key);
        }).map(function (item) {
          return item.val;
        });
        _this.$set(_this.user, "role", newUserRoses);
      }).catch(function (err) {
        _this.loading = false;
        (0, _log.showDebugLog)("page-user-err", err);
        (0, _log.displayPopupMessage)(err, "error");
      });
    },
    getUserCoin: function getUserCoin(token, uuid) {
      var _this2 = this;
      (0, _coin.getUserCoin)(token, uuid, false).then(function (response) {
        // console.log('page-useredit-coin-response', response.data)
        _this2.coin = response.data;
        // console.log('coin', this.coin)
      }).catch(function (err) {
        (0, _log.showDebugLog)("page-user-err", err);
        (0, _log.displayPopupMessage)(err, "error");
      });
    }
  }
};