"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lifehikesInitData = require("@/lifehikes-init-data");
var _vue2Editor = require("vue2-editor");
var _log = require("@/utils/log");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _cookie = require("@/utils/cookie");
var _resource = require("@/api/resource");
var _user = require("@/api/user");
var _index = _interopRequireDefault(require("../../../../components/EventTime/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var tmpLoopGeninfo = {};
var _default = exports.default = {
  name: "LoopGeninfo",
  components: {
    VueEditor: _vue2Editor.VueEditor,
    EventTime: _index.default
  },
  props: {
    parentLoop: {
      type: Object,
      default: tmpLoopGeninfo
    }
  },
  data: function data() {
    return {
      dialogResourceVisible: false,
      dialogContactVisible: false,
      tableLoading: false,
      loopType: _lifehikesInitData.loopType,
      resourceQuery: {
        page: 1,
        limit: 999,
        q: undefined,
        type: undefined,
        sort: "title"
      },
      contactQuery: {
        page: 1,
        limit: 999,
        q: undefined,
        sort: "-updatedAt"
      },
      resourceOptions: [],
      userList: [],
      customToolbar: [["bold"], [{
        list: "bullet"
      }]],
      loading: false,
      timeZoneList: _lifehikesInitData.ianaTimeZone,
      countryList: [],
      tagInputVisible: false /* use for Tag component */,
      tagInputValue: "" /* use for tag component */,
      loop: {}
    };
  },
  watch: {
    loop: {
      handler: function handler(newVal) {
        this.passBackGenInfo(newVal);
      },
      deep: true
    }
  },
  created: function created() {
    var _this = this;
    this.loop = this.parentLoop;
    console.info("loop info");
    console.info(this.loop);
    //console.info(JSON.stringify(this.parentLoop));
    this.$store.dispatch("facets/getInitData", "en").then(function () {
      _this.countryList = _this.$store.getters.countries;
    });
  },
  methods: {
    prefillFeedbackLink: function prefillFeedbackLink() {
      this.loop.feedbackUrl = "https://otr.link/".concat(this.loop.internal.opportunityNumber);
    },
    passBackGenInfo: function passBackGenInfo(myLoop) {
      console.info(myLoop);
      this.$emit("updateParentGenInfo", myLoop);
    },
    openResourceDialog: function openResourceDialog(type) {
      this.dialogResourceVisible = true;
      this.resourceQuery.type = type;
      this.getResources();
    },
    getResources: function getResources() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)("ACCESS_TOKEN");
      this.tableLoading = true;
      (0, _resource.fetchList)(token, this.resourceQuery).then(function (response) {
        var data = response.data;
        _this2.resourceOptions = data.rows;
        _this2.tableLoading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)("Resource-API-response", error);
        _this2.tableLoading = false;
        displayPopupMessage(error, "error");
      });
    },
    addResourceInStage: function addResourceInStage(id, title) {
      this.loop.welcomeBox.resource = id;
      this.loop.welcomeBox.resourceTitle = title;
      this.dialogResourceVisible = false;
    },
    clearReplayResource: function clearReplayResource() {
      this.loop.welcomeBox.resource = "";
      this.loop.welcomeBox.resourceTitle = "";
      this.$forceUpdate();
    },
    openContactDialog: function openContactDialog() {
      this.dialogContactVisible = true;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      var token = (0, _cookie.getCookie)("ACCESS_TOKEN");
      this.tableLoading = true;
      (0, _user.getUserList)(token, this.contactQuery).then(function (response) {
        var data = response.data;
        _this3.userList = data.rows;
        _this3.tableLoading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)("GetUserList-api-error", error);
        _this3.tableLoading = false;
        displayPopupMessage(error, "error");
        // this.$message({ type: 'error', showClose: true, duration: 5000, message: error.response.data.message })
      });
    },
    addContactInStage: function addContactInStage(userinfo) {
      this.loop.welcomeBox.coachUuid = userinfo.id;
      this.loop.welcomeBox.coachUgivenName = userinfo.givenName + " " + userinfo.familyName;
      this.dialogContactVisible = false;
    },
    clearContactResource: function clearContactResource() {
      this.loop.welcomeBox.coachUuid = null;
      this.loop.welcomeBox.coachUgivenName = "";
      this.$forceUpdate();
    },
    showUTCDate: function showUTCDate(utcDate) {
      //'YYYY-MM-DD HH:mm:ss [GMT]Z[ (]z[)]'
      //'YYYY-MM-DD HH:mm:ss (zZ)'
      return (0, _momentTimezone.default)(utcDate).utc().format('YYYY-MM-DD HH:mm:ss (z+0)');
    }
  }
};