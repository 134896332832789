"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _training = require("@/api/training");
var _cookie = require("@/utils/cookie");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: null,
      lastMonthList: null,
      loading: true,
      loadingLastMonth: true,
      title: ''
    };
  },
  created: function created() {
    this.getCurrentMonthITL();
    this.getLastMonthITL();
    this.title = (0, _moment.default)().format('MMM YYYY');
  },
  methods: {
    getCurrentMonthITL: function getCurrentMonthITL() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      // var dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      var dateFrom = (0, _moment.default)().startOf('month').format('YYYY-MM-DD');
      var dateTo = (0, _moment.default)().endOf('month').format('YYYY-MM-DD 23:59:59');
      // console.log('dateForm', dateFrom)
      var query = {
        page: 1,
        limit: 100,
        sort: '-createdAt',
        publish: 'published',
        after: dateFrom,
        before: dateTo
      };
      (0, _training.getTrainingList)(token, query).then(function (response) {
        _this.list = response.data.rows ? response.data.rows : [];
        _this.loading = false;
      }).catch(function (error) {
        console.log(error);
        _this.loading = false;
      });
    },
    getLastMonthITL: function getLastMonthITL() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var dateFrom = (0, _moment.default)().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      var dateTo = (0, _moment.default)().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59');
      // console.log('dateForm', dateFrom)
      // console.log('dateTo', dateTo)
      var query = {
        page: 1,
        limit: 100,
        sort: '-createdAt',
        publish: 'published',
        after: dateFrom,
        before: dateTo
      };
      (0, _training.getTrainingList)(token, query).then(function (response) {
        _this2.lastMonthList = response.data.rows ? response.data.rows : [];
        _this2.loadingLastMonth = false;
      }).catch(function (error) {
        console.log(error);
        _this2.loadingLastMonth = false;
      });
    }
  }
};