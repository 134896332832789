"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createLoop = createLoop;
exports.enrollLoop = enrollLoop;
exports.getLoopById = getLoopById;
exports.getLoopList = getLoopList;
exports.getLoopStaff = getLoopStaff;
exports.getParticipant = getParticipant;
exports.loopNotifyLoopClientPOC = loopNotifyLoopClientPOC;
exports.unenrollParticipant = unenrollParticipant;
exports.updateLoopById = updateLoopById;
exports.updateLoopParticipantEnrolledBy = updateLoopParticipantEnrolledBy;
var _request = _interopRequireDefault(require("@/utils/request"));
function getLoopList(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/loops',
    method: 'get',
    headers: headers,
    params: query
  });
}
function getLoopById(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/loops/' + id,
    method: 'get',
    headers: headers
  });
}
function createLoop(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/loops',
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateLoopById(token, id, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/loops/' + id,
    method: 'put',
    headers: headers,
    data: data
  });
}
function getParticipant(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/loops/' + id + '/participants',
    method: 'get',
    headers: headers
  });
}
function unenrollParticipant(token, loopId, userUuid) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/loops/' + loopId + '/users/' + userUuid + '/unenroll',
    method: 'post',
    headers: headers
  });
}
function getLoopStaff(token, id, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/loops/' + id + '/staff',
    method: 'get',
    headers: headers,
    params: query
  });
}
function enrollLoop(token, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/user-loops/register-enroll',
    method: 'post',
    headers: headers,
    data: data
  });
}
function loopNotifyLoopClientPOC(token, id, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/loops/' + id + '/staffs/notify',
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateLoopParticipantEnrolledBy(token, userId, loopId, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/user-loops/users/' + userId + '/loops/' + loopId + '/enrolledBy',
    method: 'post',
    headers: headers,
    data: data
  });
}