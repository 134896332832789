"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _log = require("@/utils/log");
var _lifehikesInitData = require("@/lifehikes-init-data");
var _SurveyImageInput = _interopRequireDefault(require("@/views/survey-builder/components/SurveyImageInput"));
var _SurveyMultipleTextbox = _interopRequireDefault(require("@/views/survey-builder/components/SurveyMultipleTextbox"));
var _SurveyMultipleOptionInput = _interopRequireDefault(require("@/views/survey-builder/components/SurveyMultipleOptionInput"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SurveyPage',
  components: {
    SurveyImageInput: _SurveyImageInput.default,
    SurveyMultipleTextbox: _SurveyMultipleTextbox.default,
    SurveyMultipleOptionInput: _SurveyMultipleOptionInput.default
  },
  props: {
    pages: Array,
    isInUse: String
  },
  data: function data() {
    var _this = this;
    if (this.pages.length > 0) {
      this.pages.forEach(function (page, index) {
        _this.addPropsToPage(page);
        page.label = 'Page ' + (index + 1);
        page.questions.forEach(function (question) {
          // If question has no "deletable" property, init question's "isDeletable" property
          if (!('isDeletable' in question)) {
            question.isDeletable = true;
          }
        });
      });
    }
    var defaultQuestionForm = {
      // _id: '',
      subcategory: [],
      questionFormat: 'text',
      questionTextObjects: [{
        value: ''
      }],
      // convert to questionText
      questionImgUrl: [],
      // temporarily fixed
      questionVideoUrl: [],
      // temporarily fixed
      answerFormat: '',
      answerOptions: [{
        value: '',
        label: ''
      }],
      // convert to answerOptions
      answerMaxValue: 10,
      answerMinValue: 1,
      minValueLabel: '',
      maxValueLabel: '',
      answerTextPlaceHolder: '',
      noOfTextbox: 7,
      buttonText: 'OK',
      // temporarily fixed
      buttonColor: '#0077b6',
      // temporarily fixed
      buttonTextColor: '#FFFFFF',
      // temporarily fixed
      isEnabled: 'Y',
      isRequired: 'Y',
      tags: []
    };
    return {
      validImageExtension: _lifehikesInitData.validImageExtension,
      // Page list
      currentPage: this.pages.length > 0 ? this.pages[0] : null,
      // Question Dialog
      questionDialogVisible: false,
      defaultQuestionForm: defaultQuestionForm,
      questionForm: JSON.parse(JSON.stringify(defaultQuestionForm)),
      questionFormRules: {
        questionFormat: [{
          required: true,
          message: 'Please select question format',
          trigger: 'blur'
        }],
        answerFormat: [{
          required: true,
          message: 'Please select answer format',
          trigger: 'blur'
        }]
      },
      editingQuestionIndex: null
    };
  },
  methods: {
    // public methods
    moveUp: function moveUp(list, index) {
      if (index === 0) {
        return;
      }
      var item = list.splice(index, 1)[0];
      list.splice(index - 1, 0, item);
    },
    moveDown: function moveDown(list, index) {
      if (index === list.length - 1) {
        return;
      }
      var item = list.splice(index, 1)[0];
      list.splice(index + 1, 0, item);
    },
    // Page list
    handleAddPage: function handleAddPage() {
      var newPage = {
        title: '',
        headerImg: '',
        // Questions
        questions: []
      };
      this.addPropsToPage(newPage);
      newPage.label = 'Page ' + (this.pages.length + 1);
      newPage.title = 'Page ' + (this.pages.length + 1);
      this.pages.push(newPage);
      this.currentPage = newPage;
    },
    handlePageClick: function handlePageClick(page) {
      this.currentPage = page;
    },
    handlePageDelete: function handlePageDelete(index) {
      // When the current page is deleted
      if (this.pages[index] === this.currentPage) {
        this.currentPage = null;
      }
      this.pages.splice(index, 1);
      this.resetPageLabels();
    },
    handlePageMoveUp: function handlePageMoveUp(pageIndex) {
      this.currentPage = this.pages[pageIndex];
      this.moveUp(this.pages, pageIndex);
      this.resetPageLabels();
    },
    handlePageMoveDown: function handlePageMoveDown(pageIndex) {
      this.currentPage = this.pages[pageIndex];
      this.moveDown(this.pages, pageIndex);
      this.resetPageLabels();
    },
    addPropsToPage: function addPropsToPage(page) {
      // Label in page list
      page.label = '';

      // If page has no "deletable" property, init page's "isDeletable" property
      if (!('isDeletable' in page)) {
        page.isDeletable = true;
      }

      // Upload Img properties
      page.thumbnailFile = null;
      page.showThumbnailUploadErrMsg = false;
    },
    resetPageLabels: function resetPageLabels() {
      for (var i = 0, l = this.pages.length; i < l; i++) {
        this.pages[i].label = 'Page ' + (i + 1);
      }
    },
    // Question list
    handleAddQuestion: function handleAddQuestion() {
      this.questionDialogVisible = true;
    },
    handleEditQuestion: function handleEditQuestion(row, index) {
      var questionForm = JSON.parse(JSON.stringify(row));
      questionForm.questionTextObjects = questionForm.questionText.map(function (text) {
        return {
          value: text
        };
      });
      delete questionForm.questionText;
      this.questionForm = questionForm;
      this.questionDialogVisible = true;
      this.editingQuestionIndex = index;
    },
    handleDeleteQuestion: function handleDeleteQuestion(questions, index) {
      questions.splice(index, 1);
    },
    // Qustion dialog
    handleMaxValueLabelChange: function handleMaxValueLabelChange(questionForm) {
      questionForm.maxValueLabel = questionForm.maxValueLabel.trim();
    },
    handleMinValueLabelChange: function handleMinValueLabelChange(questionForm) {
      questionForm.minValueLabel = questionForm.minValueLabel.trim();
    },
    confirmQuestion: function confirmQuestion() {
      var _this2 = this;
      this.$refs.questionForm.validate(function (valid) {
        if (valid) {
          // Modify data to match backend API
          var question = _this2.questionForm;
          question.questionText = question.questionTextObjects.map(function (obj) {
            return obj.value;
          });
          delete question.questionTextObjects;

          // Init question's "isDeletable" property
          question.isDeletable = true;
          if (_this2.editingQuestionIndex === null) {
            _this2.currentPage.questions.push(question);
          } else {
            _this2.currentPage.questions.splice(_this2.editingQuestionIndex, 1, question); // Replace the original question
            _this2.editingQuestionIndex = null;
          }
          _this2.questionDialogVisible = false;
          _this2.questionForm = JSON.parse(JSON.stringify(_this2.defaultQuestionForm));
        }
      });
    },
    cancelQuestion: function cancelQuestion() {
      if (this.editingQuestionIndex !== null) {
        this.editingQuestionIndex = null;
      }
      this.$refs.questionForm.clearValidate();
      this.questionDialogVisible = false;
      this.questionForm = JSON.parse(JSON.stringify(this.defaultQuestionForm));
    }
  }
};