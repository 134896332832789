"use strict";

var _interopRequireDefault = require("/codebuild/output/src3876308524/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DragSelect = _interopRequireDefault(require("@/components/DragSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// base on element-ui
var _default = exports.default = {
  name: 'DragSelectDemo',
  components: {
    ElDragSelect: _DragSelect.default
  },
  data: function data() {
    return {
      value: ['Apple', 'Banana', 'Orange'],
      options: [{
        value: 'Apple',
        label: 'Apple'
      }, {
        value: 'Banana',
        label: 'Banana'
      }, {
        value: 'Orange',
        label: 'Orange'
      }, {
        value: 'Pear',
        label: 'Pear'
      }, {
        value: 'Strawberry',
        label: 'Strawberry'
      }]
    };
  }
};